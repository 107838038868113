/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2",
    em: "em",
    ul: "ul",
    li: "li",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "underline-tabs",
    style: {
      position: "relative"
    }
  }, "Underline Tabs", React.createElement(_components.a, {
    href: "#underline-tabs",
    "aria-label": "underline tabs permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Underline Tabs are best used when switching between related content within a page."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={0}>\n\t{([active, setActive]) => (\n\t\t<TabGroup>\n\t\t\t<Tab onClick={() => setActive(0)} active={active === 0}>All</Tab>\n\t\t\t<Tab onClick={() => setActive(1)} active={active === 1}>Action Required</Tab>\n\t\t\t<Tab onClick={() => setActive(2)} active={active === 2}>Insights</Tab>\n\t\t\t<Tab onClick={() => setActive(3)} active={active === 3}>Settings</Tab>\n\t\t</TabGroup>\n\t)}\n</State>\n")), "\n", React.createElement(_components.p, null, "Underline tabs have a minimum width of 48px. The text and hover/active underline are always equal in width beyond this minimum."), "\n", React.createElement(_components.h2, {
    id: "with-badges",
    style: {
      position: "relative"
    }
  }, "With Badges", React.createElement(_components.a, {
    href: "#with-badges",
    "aria-label": "with badges permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<TabGroup>\n\t<Tab>Ready To Bill <Tag compact badge>127</Tag></Tab>\n\t<Tab active>Action Required <Tag compact badge color=\"critical\">25</Tag></Tab>\n\t<Tab>Insights</Tab>\n\t<Tab>Settings</Tab>\n</TabGroup>\n")), "\n", React.createElement(_components.h2, {
    id: "with-icons",
    style: {
      position: "relative"
    }
  }, "With Icons", React.createElement(_components.a, {
    href: "#with-icons",
    "aria-label": "with icons permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<TabGroup>\n\t<Tab active><Icon name=\"info\" />Action Requested</Tab>\n\t<Tab><Icon name=\"poll\" /> Insights</Tab>\n\t<Tab><Icon name=\"settings\" /> Settings</Tab>\n</TabGroup>\n")), "\n", React.createElement(_components.h2, {
    id: "tab-action",
    style: {
      position: "relative"
    }
  }, "Tab Action", React.createElement(_components.a, {
    href: "#tab-action",
    "aria-label": "tab action permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Tab actions, represented with a button or icon, can be represented as a tab itself. This can be useful for non-navigational actions related to the tab content such as adding a new tab."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<TabGroup\n\taction={\n\t\t<Button size=\"xsmall\" primary>Call to Action</Button>\n\t}\n>\n\t<style>{`\n\t  .icon-hov {\n\t\t\tcursor: pointer;\n\t\t}\n\t\t.icon-hov:hover svg { fill: #2270ee; }\n\t`}</style>\n\t<Tab active>Action Requested</Tab>\n\t<Tab> Insights</Tab>\n</TabGroup>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<TabGroup\n\taction={\n\t\t<Icon name=\"add_circle\" className=\"icon-hov fs-4 c-neutral-200\" />\n\t}\n>\n\t<style>{`\n\t  .icon-hov {\n\t\t\tcursor: pointer;\n\t\t}\n\t\t.icon-hov:hover svg { fill: #2270ee; }\n\t`}</style>\n\t<Tab active>Action Requested</Tab>\n\t<Tab> Insights</Tab>\n</TabGroup>\n")), "\n", React.createElement(_components.h2, {
    id: "divided-tabs",
    style: {
      position: "relative"
    }
  }, "Divided Tabs", React.createElement(_components.a, {
    href: "#divided-tabs",
    "aria-label": "divided tabs permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A vertical pipe (", React.createElement(_components.code, null, "|"), ") divider can be applied to both sides of an inactive tab. This is useful when there are many tabs on a page."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={0}>\n\t{([active, setActive]) => (\n\t\t<TabGroup\n\t\t\tverticallyDivided\n\t\t\taction={\n\t\t\t\t<Button size=\"xsmall\" primary>Call to Action</Button>\n\t\t\t}\n\t\t>\n\t\t\t<Tab active={active == 0} onClick={() => setActive(0)}>All</Tab>\n\t\t\t<Tab active={active == 1} onClick={() => setActive(1)}>Action Required</Tab>\n\t\t\t<Tab active={active == 2} onClick={() => setActive(2)}>Insights</Tab>\n\t\t\t<Tab active={active == 3} onClick={() => setActive(3)}>Action Required</Tab>\n\t\t\t<Tab active={active == 4} onClick={() => setActive(4)}>Insights</Tab>\n\t\t\t<Tab active={active == 5} onClick={() => setActive(5)}>Action Required</Tab>\n\t\t</TabGroup>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h2, {
    id: "fitted",
    style: {
      position: "relative"
    }
  }, "Fitted", React.createElement(_components.a, {
    href: "#fitted",
    "aria-label": "fitted permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Card>\n\t<Card.Section>\n\t\t<TabGroup fitted divider={false}>\n\t\t\t<Tab active><Icon name=\"info\" />Action Requested</Tab>\n\t\t\t<Tab><Icon name=\"poll\" /> Insights</Tab>\n\t\t\t<Tab ><Icon name=\"settings\" /> Settings</Tab>\n\t\t</TabGroup>\n\t</Card.Section>\n\t<Card.Section>\n\tThis card has supporting text below as a natural lead-in to additional content.\n\t</Card.Section>\n</Card>\n")), "\n", React.createElement(_components.p, null, "Underline tabs can be fitted to a card. This pattern should only be used when there are 2 or 3 tabs."), "\n", React.createElement(_components.h1, {
    id: "index-tabs",
    style: {
      position: "relative"
    }
  }, "Index Tabs", React.createElement(_components.a, {
    href: "#index-tabs",
    "aria-label": "index tabs permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Index Tabs are best used when switching between tabs only affects content within the visual container its attached to. They can also be nested within an underline tab."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<TabGroup type=\"index\">\n\t\t<Tab active>All</Tab>\n\t\t<Tab>Action Required</Tab>\n\t\t<Tab>Insights</Tab>\n\t\t<Tab>Settings</Tab>\n\t</TabGroup>\n")), "\n", React.createElement(_components.p, null, "Index tabs have a minimum width of 64px. The text and hover/active underline are always equal in width beyond this minimum."), "\n", React.createElement(_components.h2, {
    id: "with-badges-1",
    style: {
      position: "relative"
    }
  }, "With Badges", React.createElement(_components.a, {
    href: "#with-badges-1",
    "aria-label": "with badges 1 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<TabGroup type=\"index\">\n\t\t<Tab>Ready To Bill <Tag compact badge>127</Tag></Tab>\n\t\t<Tab active>Action Required <Tag compact badge color=\"critical\">25</Tag></Tab>\n\t\t<Tab>Insights</Tab>\n\t\t<Tab>Settings</Tab>\n\t</TabGroup>\n")), "\n", React.createElement(_components.h2, {
    id: "with-icons-1",
    style: {
      position: "relative"
    }
  }, "With Icons", React.createElement(_components.a, {
    href: "#with-icons-1",
    "aria-label": "with icons 1 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<TabGroup type=\"index\">\n\t\t<Tab active><Icon name=\"info\" />Action Requested</Tab>\n\t\t<Tab><Icon name=\"poll\" /> Insights</Tab>\n\t\t<Tab><Icon name=\"settings\" /> Settings</Tab>\n\t</TabGroup>\n")), "\n", React.createElement(_components.h2, {
    id: "fitted-1",
    style: {
      position: "relative"
    }
  }, "Fitted", React.createElement(_components.a, {
    href: "#fitted-1",
    "aria-label": "fitted 1 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Card padding=\"thin\">\n\t<Card.Section>\n\t\t<TabGroup type=\"index\" fitted divider={false}>\n\t\t\t<Tab>Action Required</Tab>\n\t\t\t<Tab>Insights</Tab>\n\t\t\t<Tab active>Settings</Tab>\n\t\t</TabGroup>\n\t</Card.Section>\n\t<Card.Section>\n\t  Lorem ipsum dolar emit\n\t</Card.Section>\n</Card>\n")), "\n", React.createElement(_components.h2, {
    id: "max-width",
    style: {
      position: "relative"
    }
  }, "Max Width", React.createElement(_components.a, {
    href: "#max-width",
    "aria-label": "max width permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Prevents a tab from exceeding ", React.createElement(_components.code, null, "240px"), " in length. When combined with the ", React.createElement(_components.em, null, "fitted"), " property, can make tabs stretch to the maximum width."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<TabGroup type=\"index\" fitted maxWidth>\n\t\t<Tab active>All</Tab>\n\t\t<Tab>Action Required</Tab>\n\t\t<Tab>Insights</Tab>\n\t</TabGroup>\n")), "\n", React.createElement(_components.h2, {
    id: "left-aligned-tab-text",
    style: {
      position: "relative"
    }
  }, "Left Aligned Tab Text", React.createElement(_components.a, {
    href: "#left-aligned-tab-text",
    "aria-label": "left aligned tab text permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "By default, text is centered within a tab."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<TabGroup type=\"index\" fitted maxWidth textAlign=\"left\">\n\t\t<Tab active>John Doe</Tab>\n\t\t<Tab>New Tab</Tab>\n\t\t<Tab>Insights</Tab>\n\t</TabGroup>\n")), "\n", React.createElement(_components.h2, {
    id: "divided-tabs-1",
    style: {
      position: "relative"
    }
  }, "Divided Tabs", React.createElement(_components.a, {
    href: "#divided-tabs-1",
    "aria-label": "divided tabs 1 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={0}>\n\t{([active, setActive]) => (\n\t\t<TabGroup\n\t\t\ttype=\"index\"\n\t\t\tverticallyDivided\n\t\t\taction={\n\t\t\t\t<Button size=\"xsmall\" primary>Call to Action</Button>\n\t\t\t}\n\t\t>\n\t\t\t<Tab active={active == 0} onClick={() => setActive(0)}>All</Tab>\n\t\t\t<Tab active={active == 1} onClick={() => setActive(1)}>Action Required</Tab>\n\t\t\t<Tab active={active == 2} onClick={() => setActive(2)}>Insights</Tab>\n\t\t\t<Tab active={active == 3} onClick={() => setActive(3)}>Action Required</Tab>\n\t\t\t<Tab active={active == 4} onClick={() => setActive(4)}>Insights</Tab>\n\t\t\t<Tab active={active == 5} onClick={() => setActive(5)}>Action Required</Tab>\n\t\t</TabGroup>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h2, {
    id: "with-a-background",
    style: {
      position: "relative"
    }
  }, "With a Background", React.createElement(_components.a, {
    href: "#with-a-background",
    "aria-label": "with a background permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Tabs can take advantage of different background colors. The blue active state and dividers are removed, and inactive tabs switch text color."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className='box-demo'>\n\t<TabGroup type=\"index\" dark divider={false}>\n\t\t<Tab active>All</Tab>\n\t\t<Tab>Action Required</Tab>\n\t\t<Tab>Insights</Tab>\n\t\t<Tab>Settings</Tab>\n\t</TabGroup>\n\t<style>{`\n\t  .box-demo {\n\t\t\tbackground: #8C9CA5;\n\t\t\tpadding: 20px 20px 0;\n\t\t}\n\t  .tab-demo {\n\t\t\tbackground: white;\n\t\t\twidth: calc(100% + 40px);\n\t\t\theight: 40px;\n\t\t\tmargin: 0 -20px;\n\t\t}\n\t`}</style>\n\t<div className='tab-demo' />\n</div>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className='box-demo-2'>\n\t<TabGroup type=\"index\" dark verticallyDivided divider={false}>\n\t\t<Tab active>All</Tab>\n\t\t<Tab>Action Required</Tab>\n\t\t<Tab>Insights</Tab>\n\t\t<Tab>Settings</Tab>\n\t</TabGroup>\n\t<style>{`\n\t  .box-demo-2 {\n\t\t\tbackground: #232323;\n\t\t\tpadding: 20px 20px 0;\n\t\t}\n\t  .tab-demo {\n\t\t\tbackground: white;\n\t\t\twidth: calc(100% + 40px);\n\t\t\theight: 40px;\n\t\t\tmargin: 0 -20px;\n\t\t}\n\t`}</style>\n\t<div className='tab-demo' />\n</div>\n")), "\n", React.createElement(_components.h2, {
    id: "sizing",
    style: {
      position: "relative"
    }
  }, "Sizing", React.createElement(_components.a, {
    href: "#sizing",
    "aria-label": "sizing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A taller variation of index tabs can be used for important navigation at the top of a page."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<TabGroup type=\"index\" size=\"large\">\n\t\t<Tab active>Customers</Tab>\n\t\t<Tab>Available Inventory</Tab>\n\t\t<Tab>Analytics</Tab>\n\t\t<Tab>Marketing</Tab>\n\t</TabGroup>\n")), "\n", React.createElement(_components.h2, {
    id: "nested",
    style: {
      position: "relative"
    }
  }, "Nested", React.createElement(_components.a, {
    href: "#nested",
    "aria-label": "nested permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "If a page needs multiple levels of tabs, the index tab can be nested in a underline tab."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div>\n\t<TabGroup>\n\t\t<Tab active>All</Tab>\n\t\t<Tab>Action Required</Tab>\n\t\t<Tab>Insights</Tab>\n\t\t<Tab>Settings</Tab>\n\t</TabGroup>\n\t<br/>\n\t<Card padding=\"thin\">\n\t\t<Card.Section>\n\t\t\t<TabGroup type=\"index\" divider={false}>\n\t\t\t\t<Tab>SubTab 1</Tab>\n\t\t\t\t<Tab>SubTab 2</Tab>\n\t\t\t\t<Tab active>SubTab 3</Tab>\n\t\t\t\t<Tab>SubTab 4</Tab>\n\t\t\t\t<Tab>SubTab 5</Tab>\n\t\t\t</TabGroup>\n\t\t</Card.Section>\n\t\t<Card.Section>\n\t\t\tLorem ipsum dolar emit\n\t\t</Card.Section>\n\t</Card>\n</div>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, "Responsive", React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Tabs will scroll horizontally when overflowed."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div>\n\t<Headline className=\"m-b-3\">Horizontal scrolling</Headline>\n\t<TabGroup>\n\t\t<Tab active>All</Tab>\n\t\t<Tab>Action Required</Tab>\n\t\t<Tab>Insights</Tab>\n\t\t<Tab>Settings</Tab>\n\t\t<Tab>More Items</Tab>\n\t\t<Tab>Sixth Item</Tab>\n\t</TabGroup>\n\t<br/>\n\t<Card padding=\"thin\">\n\t\t<Card.Section>\n\t\t\t<TabGroup type=\"index\" divider={false}>\n\t\t\t\t<Tab>SubTab 1</Tab>\n\t\t\t\t<Tab active>SubTab 2</Tab>\n\t\t\t\t<Tab>SubTab 3</Tab>\n\t\t\t\t<Tab>SubTab 4</Tab>\n\t\t\t\t<Tab>SubTab 5</Tab>\n\t\t\t\t<Tab>SubTab 6</Tab>\n\t\t\t</TabGroup>\n\t\t</Card.Section>\n\t\t<Card.Section>\n\t\t\tLorem ipsum dolar emit\n\t\t</Card.Section>\n\t</Card>\n</div>\n")), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best Practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Avoid tab names over 2 words long."), "\n", React.createElement(_components.li, null, "Should be used with 2–10 choices."), "\n", React.createElement(_components.li, null, "Only 1 tab should be active at a time."), "\n", React.createElement(_components.li, null, "Avoid the disabled state. If a user cannot use it, remove it from the tab."), "\n", React.createElement(_components.li, null, "Content within a tab should be mutually exclusive from another tab's content."), "\n", React.createElement(_components.li, null, "A visual divider should always be present. The default divider is a gray line."), "\n", React.createElement(_components.li, null, "On tab switch, the ", React.createElement(_components.code, null, "<title>"), " should change to reflect the new content."), "\n", React.createElement(_components.li, null, "More UX practices: ", React.createElement(_components.a, {
    href: "https://www.nngroup.com/articles/tabs-used-right/"
  }, "Tabs, Used Right"), " (NN Group)"), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { TabGroup, Tab } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
